import React, { useState } from "react"
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'components';
import { products, features } from 'constants/menu';
import { POUCHERS_APP_URL, POUCHERS_WEBSITE_URL } from 'constants';
import { Link } from 'react-router-dom';

export default function MobileMenu({ showSlider, setShowSlider }) {
  const [showMobileProducts, setShowMobileProducts] = useState(false)
  const [showMobileFeatures, setShowMobileFeatures] = useState(false)

  return (
    <AnimatePresence>
      {
        showSlider &&
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: '0', transition: { duration: .8 } }}
          exit={{ x: '-100%' }}
          className='absolute sm:hidden top-[60px] bg-purple text-white text-20 w-full h-[93vh] overflow-y-auto py-[40px]'
        >
          <ul>
            <li
              className='mr-[32px] hide_tap cursor-pointer'
              onClick={() => {
                setShowMobileFeatures(false)
                setShowMobileProducts(!showMobileProducts)
              }}
            >
              <div className='flex items-center'>
                <span className='px-[16px] py-[10px]'>Products</span>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1.5L6 6.5L11 1.5" stroke="#F7F9FB" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <AnimatePresence>
                {
                  showMobileProducts &&
                  <motion.ul
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1, transition: { type: 'spring', duration: 1 } }}
                    exit={{ height: 0, opacity: 0 }}
                    className='rounded-[16px] text-white w-full'
                  >
                    {
                      products.map((product) =>
                        <li
                          key={product.id}
                          onClick={() => {
                            setShowMobileProducts(!showMobileProducts)
                            setShowSlider(false)
                          }}
                        >
                          <Link to={`/products/${product.url}`} className='block w-full px-[32px] py-[4px] capitalize'>{product.name}</Link>
                        </li>
                      )
                    }
                  </motion.ul>
                }
              </AnimatePresence>
            </li>
            <li
              className='mr-[32px] hide_tap cursor-pointer mb-[10px]'
              onClick={() => {
                setShowMobileProducts(false)
                setShowMobileFeatures(!showMobileFeatures)
              }}
            >
              <div className='flex items-center'>
                <span className='px-[16px] py-[10px]'>Features</span>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1.5L6 6.5L11 1.5" stroke="#F7F9FB" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <AnimatePresence>
                {
                  showMobileFeatures &&
                  <motion.ul
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1, transition: { type: 'spring', duration: 1 } }}
                    exit={{ height: 0, opacity: 0 }}
                    className='rounded-[16px] text-white w-full'
                  >
                    {
                      features.map((feature) =>
                        <li
                          key={feature.id}
                          onClick={() => {
                            setShowMobileFeatures(!showMobileFeatures)
                            setShowSlider(false)
                          }}
                        >
                          <Link to={`/${feature.url}`} className='block w-full px-[32px] py-[4px] capitalize'>{feature.name}</Link>
                        </li>
                      )
                    }
                  </motion.ul>
                }
              </AnimatePresence>
            </li>
            <li><a href={`${POUCHERS_WEBSITE_URL}/blog`} className='px-[16px] mr-[32px] mb-[20px] sm:mb-0 hide_tap'>Blog</a></li>
          </ul>
          <div className='md:flex items-center mt-[40px] px-[16px]'>
            <a href={`${POUCHERS_APP_URL}`} target="_blank" rel="noreferrer" >
              <Button className="bg-secondary-text text-purple h-full whitespace-nowrap font-dmsans_m mr-[16px]">
                Sign Up
              </Button>
            </a>
            <a href={`${POUCHERS_APP_URL}`} target="_blank" rel="noreferrer" >
              <Button className="text-white font-dmsans_m">Login</Button>
            </a>
          </div>
        </motion.div>
      }
    </AnimatePresence>
  )
}