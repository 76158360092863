import React from 'react';
import classNames from 'classnames';
import './_button.scss';

const Button = ({ type = 'submit', children, className, ...props }) => {
  return (
    <button type={type} className={classNames('button hide_tap', className)} {...props}>
      {children}
    </button>
  );
};

export default Button;
