import React from 'react';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { useInView } from "react-intersection-observer"

import { Hero } from 'containers/Home';
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg"




export default function Support() {

    const { ref, inView } = useInView({ triggerOnce: true, threshold: .8 })

    const variants = {
        visible: { y: '0px', opacity: 1, transition: { type: 'spring', duration: 1 } },
        hidden: { y: '20px', opacity: 0 }
    };



    return (
        <>
            <div className="bg-purple">
                <Hero />
            </div>
            <div className="fixed top-0 left-0 inset-0 bg-[#0606284D] z-50 flex flex-col items-center justify-center">
                <div className="md:w-[416px] w-10/12 mb-5">
                    <Link to="/" className="w-10 h-10 flex items-center justify-center bg-[#F4F4FB] rounded-full ml-auto"><CloseIcon/></Link>
                </div>
                <motion.div
                    ref={ref}
                    variants={variants}
                    animate={inView ? 'visible' : 'hidden'}
                    initial='hidden'
                    className='mx-auto md:w-[416px] w-10/12 py-12 px-8 transition_left bg-white rounded-xl flex flex-col gap-3'
                >
                    <div className="flex flex-col gap-2">
                        <h2 className="font-dmsans_b text-24 leading-8">Get in Touch</h2>
                        <p className="font-dmsans_r text-16 leading-6 text-[#6D6D6F]">
                            We'd love to hear from you! If you have any questions or need assistance, please don't hesitate to reach out.
                        </p>
                    </div>
                    <a href="mailto:support@pouchers.io" className="bg-purple text-white rounded-lg py-3 px-20 text-center font-dmsans_m mt-7">Send Us An Email</a>
                </motion.div>
            </div>
        </>
    );
};