/* eslint-disable no-use-before-define */
import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { REGEX_PHONE } from "constants/delete";
import { deleteAccount } from "services/endpoints/delete";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg"

import Delete from "./Delete";
import DeleteCompleted from "./DeleteCompleted";
import useValidateFormData from "./hooks/useValidateFormData";


export default function DeleteForm() {

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        email_or_phone: '',
        password: '',
    })

    const handleChange = useCallback((e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value.trim() })
    }, [formData])
    const { validateFormData } = useValidateFormData()

    const [step, setStep] = useState(1)

    const onSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const key = REGEX_PHONE.test(formData.email_or_phone) ? 'phone_number' : 'email'
            if (!validateFormData({ [key]: formData.email_or_phone }) || !formData?.password) return // check if formData is valid

            const enhancedFormData = {
                [key]: formData.email_or_phone,
                password: formData.password
            }
            const res = await deleteAccount(enhancedFormData)
            if (res?.status === 200) {
                setStep(2)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message ??  "Sorry could not delete account, try again")
            setFormData({email_or_phone: "", password: ""})
        } finally {
            setLoading(false)
        }
    }




    return (
        <div className="fixed top-0 left-0 inset-0 bg-[#0606284D] z-50 flex flex-col items-center justify-center">
            <div className="md:w-[416px] w-10/12 mb-5">
                <Link to="/" className="w-10 h-10 flex items-center justify-center bg-[#F4F4FB] rounded-full ml-auto"><CloseIcon/></Link>
            </div>
            {step === 1 && <Delete formData={formData} onChange={handleChange} loading={loading} onSubmit={onSubmit} />}
            {step === 2 && <DeleteCompleted />}
        </div>
    )
}