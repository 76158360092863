import React from 'react';
import { Button } from 'components';
import { POUCHERS_APP_URL } from 'constants';

export default function CallToAction({ title, description }) {
  return (
    <div className="min-h-[414px] mb-[0.5px] background-footer-top border-b-white flex items-center">
      <div className="container py-[40px]">
        <div className="bg-purple rounded-[10px] px-[16px] lg:px-[64px] py-[32px] lg:py-[64px] flex flex-col lg:flex-row items-center justify-between drop-shadow-2xl">
          <div className="max-w-[678px] mb-[20px] lg:mb-0 text-center lg:text-left">
            <h3 className="text-32 font-dmsans_b text-white leading-[38px] mb-[16px]">{title}</h3>
            <p className="text-16 font-dmsans_r text-white leading-[30px]">{description}</p>
          </div>
            <div className="flex">
              {/* <Button className="bg-white text-dark text-16 h-full whitespace-nowrap mr-[16px]">
                Learn more
              </Button> */}
              <a href={`${POUCHERS_APP_URL}/create-account`} target="_blank" rel="noreferrer">
                <Button className="text-white bg-[#6463FD] whitespace-nowrap">SIGN UP</Button>
              </a>
            </div>
        </div>
      </div>
    </div>
  );
}
