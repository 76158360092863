import React from 'react';

import { Hero } from 'containers/Home';

import DeleteForm from 'components/Form/DeleteForm';


export default function DeleteAccount() {


    return (
        <>
            <div className="bg-purple">
                <Hero />
            </div>
            <DeleteForm/>
        </>
    );
};