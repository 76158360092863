import React, { lazy } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import Layout from 'components/Layouts';
import Home from 'pages/Home';
import DeleteAccount from 'pages/DeleteAccount';
import Support from 'pages/Support';
// Lazy loaded pages
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'))
const Referrals = lazy(() => import('pages/Referrals'))
const TermsOfUse = lazy(() => import('pages/TermsOfUse'))
const Airtime = lazy(() => import('pages/Products/Airtime'))
const Data = lazy(() => import('pages/Products/Data'))
const Cable = lazy(() => import('pages/Products/Cable'))
const Electricity = lazy(() => import('pages/Products/Electricity'))
const Betting = lazy(() => import('pages/Products/Betting'))
const Voucher = lazy(() => import('pages/Products/Voucher'))
const NairaCard = lazy(() => import('pages/Products/NairaCard'))
const DollarCard = lazy(() => import('pages/Products/DollarCard'))
const BillsAutomation  =  lazy(() =>  import('pages/BillsAutomation'))
const MoneyTransfers  =  lazy(() =>  import('pages/MoneyTransfers'))
const SpendingAnalytics  =  lazy(() =>  import('pages/SpendingAnalytics'))
const DigitalWallet  =  lazy(() =>  import('pages/DigitalWallet'))
const SomethingWentWrong  =  lazy(() =>  import('components/ErrorPage/SomethingWentWrong'))
const NotFound  =  lazy(() =>  import('components/ErrorPage/NotFound'))
const Internet  =  lazy(() =>  import('pages/Products/Internet'))

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <SomethingWentWrong />,
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />
      },
      {
        path: 'delete-account',
        element: <DeleteAccount />
      },
      {
        path: 'support',
        element: <Support />
      },
      {
        path: 'terms-of-use',
        element: <TermsOfUse/>
      },
      {
        path: 'referrals',
        element: <Referrals/>
      },
      {
        path: 'bill-scheduling-and-automation',
        element: <BillsAutomation/>
      },
      {
        path: 'spending-analytics',
        element: <SpendingAnalytics/>
      },
      {
        path: 'money-transfer',
        element: <MoneyTransfers/>
      },
      {
        path: 'digital-wallet',
        element: <DigitalWallet/>
      },
      {
        path: 'products/airtime',
        element: <Airtime />
      },
      {
        path: 'products/data',
        element: <Data />
      },
      {
        path: 'products/cable',
        element: <Cable />
      },
      {
        path: 'products/internet',
        element: <Internet />
      },
      {
        path: 'products/electricity',
        element: <Electricity />
      },
      {
        path: 'products/betting',
        element: <Betting />
      },
      {
        path: 'products/vouchers',
        element: <Voucher />
      },
      {
        path: 'products/naira-card',
        element: <NairaCard />
      },
      {
        path: 'products/dollar-card',
        element: <DollarCard />
      },
    ]
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

export default router;