import { useState } from "react"
import {
} from "constants/index.js"
import { PASSWORD_LENGTH, REGEX_EMAIL, REGEX_PASSWORD, REGEX_PHONE } from "constants/delete"

export default function useInputValidate() {
    const [error, setError] = useState('')

    const checkPassword = ({ value }) => {
        if (value.length < PASSWORD_LENGTH || !REGEX_PASSWORD.test(value)) {
            setError(`Password must contain, atleast one uppercase letter, at least one lower case letter,
            at least one digit, at least one special character and must be at least ${PASSWORD_LENGTH} characters length long.`)
            return false
        }

        setError('')
        return true
    }

    const checkEmailOrPhone = ({ value }) => {
        if (!REGEX_PHONE.test(value) && !REGEX_EMAIL.test(value)) {
            setError('Enter a valid email address or valid phone number')
            return false
        }

        setError('')
        return true
    }


    const validate = ({ name, value }) => {
        switch (name) {
            case 'password':
                return checkPassword({ value })
            case 'email_or_phone':
                return checkEmailOrPhone({ value })
            default:
                setError('')
                return true
        }
    }

    return {
        error,
        setError,
        validate
    }
}