import React, { Suspense, useEffect } from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Layout() {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        })
    }, [pathname])

    return (
        <>
            <Header />
            <Suspense
                fallback={
                    <div className='flex justify-center items-center min-h-screen'>
                        <motion.div
                            animate={{ scale: [1, 1.5, 1] }}
                            transition={{ duration: 1, repeat: Infinity }}
                            className='flex justify-center items-center min-h-screen w-[50px]'>
                            <svg width="400" height="482" viewBox="0 0 400 482" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M367.97 374.8C320.25 470.6 204.35 509 108.07 461.04C11.7803 413.09 -27.3997 297.45 20.3203 201.65C67.7003 106.51 183.6 68.1 279.89 116.06C376.18 164.02 415.35 279.66 367.97 374.8Z" fill="#5034C4" />
                                <path d="M398.62 96.3759C406.917 79.7214 374.578 46.7604 326.389 22.7554C278.2 -1.24958 232.41 -7.20837 224.114 9.4461C215.817 26.1006 248.156 59.0616 296.345 83.0666C344.534 107.072 390.324 113.03 398.62 96.3759Z" fill="#4431B5" />
                            </svg>
                        </motion.div>
                    </div>
                }
            >
                <Outlet />
            </Suspense>
            <Footer />
        </>
    )
};
