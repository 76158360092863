import React from 'react';
import classnames from 'classnames';
import './_input.scss';

const Input = ({ type, placeholder, className, onChange, alt = '', ...props }) => {
  return (
    <input
      type={type}
      alt={alt}
      placeholder={placeholder}
      onChange={onChange}
      className={classnames('input', className)}
      {...props}
    />
  );
};

export default Input;
