import React from "react"
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { ReactComponent as CheckCompleted } from "assets/svg/check-icon.svg"



export default function DeleteCompleted() {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: .8 })

    const variants = {
        visible: { y: '0px', opacity: 1, transition: { type: 'spring', duration: 1 } },
        hidden: { y: '20px', opacity: 0 }
    };



    return (
        <motion.div
            ref={ref}
            variants={variants}
            animate={inView ? 'visible' : 'hidden'}
            initial='hidden'
            className='mx-auto md:w-[416px] md:h-[441.62px] w-10/12 h-4/5 py-12 px-8 transition_left bg-white rounded-xl flex flex-col gap-7 items-center justify-center'
        >
            <CheckCompleted/>
            <div className="flex flex-col gap-3 text-center">
                <h2 className="font-dmsans_b text-24 leading-8">Confirmed</h2>
                <p className="font-dmsans_r text-16 leading-6 text-[#8F8E9B]">
                    Your account has been successfully deleted
                </p>
            </div>
            <p className="font-dmsans_r text-purple">Logged you out of all systems...</p>
        </motion.div>
    )
}
