import React from "react";
import PropTypes from 'prop-types';
import Button from "components/Button"

export default function FormButton({ name, disabled, onClick, children, theme, styles, loading, type }) {
    return(
        <Button 
            disabled={disabled} 
            onClick={onClick}
            type={type || 'submit'}
            style={{ borderRadius: '8px' }}
            className={
                `${theme === 'transparent' ? 'bg-white hover:bg-white' : ''}
                ${theme === 'smoke' ? 'bg-smoke hover:bg-white font-dmsans_m text-purple' : ''}
                ${theme === 'red' ? 'bg-red text-white' : ''}
                ${theme === 'white' ? 'bg-white border-purple text-purple border' : ''}
                ${theme === 'dark' && !disabled ? 'bg-purple hover:bg-[#5134c4ea] text-white' : ''}
                ${theme === 'dark' && disabled ? 'bg-button_disabled text-white hover:bg-button_disabled' : ''}
                ${styles} flex justify-center items-center h-[55px] py-12 px-16 text-16 transition ease-in-out duration-500 font-dmsans_m capitalize hide_tap`
            }
        >
            {
                loading && theme !== 'transparent' ? 
                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" height="24" viewBox="0 0 128 128">
                    <g>
                        <path d="M75.4 126.63a11.43 11.43 0 01-2.1-22.65 40.9 40.9 0 0030.5-30.6 11.4 11.4 0 1122.27 4.87h.02a63.77 63.77 0 01-47.8 48.05v-.02a11.38 11.38 0 01-2.93.37z" fill="#FFFFFF"/>
                        <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1000ms" repeatCount="indefinite" />
                    </g>
                </svg> : name || children
            }
        </Button>
    )
}

Button.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.element,
    theme: PropTypes.string,
    styles: PropTypes.string,
    loading: PropTypes.bool,
    type: PropTypes.string
};