import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 36000,
  headers: {},
  crossdomain: true
});

export const deleteAccount = (payload) => {
    return instance.post("/general/delete-account",payload)
}
