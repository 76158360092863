import React from 'react';

const Capsule = ({ className, children }) => {
  return (
    <div
      className={
        `bg-white inline-block px-[16px] py-[4px] rounded-[100px] sm:text-[16px] text-[12px] ${className}
      `}>
      <span className="text-purple">{children}</span>
    </div>
  );
};

export default Capsule;
