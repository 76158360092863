import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer"

// components
import Input from "./Input"
import FormButton from "./FormButton";
import useValidateFormData from "./hooks/useValidateFormData"




export default function Delete({ formData, onChange, loading, onSubmit }) {

    const { ref, inView } = useInView({ triggerOnce: true, threshold: .8 })

    const variants = {
        visible: { y: '0px', opacity: 1, transition: { type: 'spring', duration: 1 } },
        hidden: { y: '20px', opacity: 0 }
    };


    const { validateFormData } = useValidateFormData()
    const disabled = useMemo(() => {
        return !validateFormData({ "email_or_phone": formData?.email_or_phone }) || !validateFormData({ "password": formData?.password })
    }, [formData])



    return (
        <form
            autoComplete
            data-testid="form"
            onSubmit={onSubmit}
        >
            <motion.div
                ref={ref}
                variants={variants}
                animate={inView ? 'visible' : 'hidden'}
                initial='hidden'
                className='mx-auto md:w-[416px] md:h-[500px] w-10/12 py-12 px-8 transition_left bg-white rounded-xl flex flex-col gap-3'
            >
                <div className="flex flex-col gap-2">
                    <h2 className="font-dmsans_b text-24 leading-8">Confirmation</h2>
                    <p className="font-dmsans_r text-16 leading-6 text-[#8F8E9B]">
                        Please enter your email or phone number and password to complete the deletion process.
                    </p>
                </div>
                <div className="">
                    <Input
                        label="Email or phone number"
                        type="text"
                        placeholder="Enter email or phone number"
                        id="email_or_phone"
                        name="email_or_phone"
                        readOnly={loading}
                        value={formData?.email_or_phone}
                        onChange={onChange}
                    />
                </div>
                <div className="">
                    <Input
                        label="Password"
                        type="password"
                        placeholder="Enter password"
                        id="password"
                        name="password"
                        readOnly={loading}
                        value={formData?.password}
                        onChange={onChange}
                    />
                </div>
                <FormButton
                    name="Delete Account"
                    theme="red"
                    disabled={disabled || loading}
                    loading={loading}
                    styles="my-4 w-full"
                    type="submit"
                />
            </motion.div>
        </form>
    )
}

Delete.propTypes = {
    onChange: PropTypes.func,
    formData: PropTypes.object,
    loading: PropTypes.bool,
    onSubmit: PropTypes.func
}