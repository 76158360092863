import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className='overflow-x-hidden'>
      <HelmetProvider>
        <RouterProvider router={router} />
        <Toaster position="top-center" />
      </HelmetProvider>
    </div>
  );
}

export default App;