import React, { useMemo, useState } from "react"
import PropTypes from 'prop-types';
// Icons
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import useInputValidate from "./hooks/useInputValidate";

export default function Input({
    id, name, placeholder, value, label, onChange, type, readOnly,
    style, maxLength, defaultValue, max, pattern, inputMode, showError, minLength
}) {
    const [passwordView, setPasswordView] = useState(false)
    const { error, validate, setError } = useInputValidate(showError)

    const inputError = useMemo(() => {
        return !(showError === false || !error)
    }, [error])


    return (
        <>
            <label htmlFor={id} className="text-grey mb-2 block text-12 font-dmsans_r">{label}</label>
            <div className="relative mb-2">
                <input
                    id={id}
                    name={name}
                    type={type === 'password' ? (passwordView ? 'text' : 'password') : type}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    maxLength={maxLength}
                    minLength={minLength}
                    inputMode={inputMode}
                    max={max}
                    pattern={pattern}
                    data-testid={`test-${id}`}
                    aria-labelledby={id}
                    onChange={onChange}
                    readOnly={readOnly || false}
                    autoComplete="off"
                    onBlur={() => validate({ name, value })}
                    onKeyDown={() => setError('')}
                    className={
                        `${inputError ? 'border-red' : 'border-purple_transparent'} 
                        p-4 text-16 w-full outline-0 border font-dmsans_r font-normal hide_tap
                        rounded-lg focus:border-purple focus:border bg-purple_transparent
                        ${type === 'password' && 'pr-10' }
                        `
                    }
                />
                {
                    type === 'password' &&
                    <button
                        type="button"
                        data-testid="view"
                        className="absolute bottom-1/2 translate-y-1/2 right-3 cursor-pointer hide_tap rounded-lg w-fit h-fit"
                        onClick={() => setPasswordView(!passwordView)}
                    >
                        {
                            passwordView ? <AiOutlineEyeInvisible data-testid='hide' /> : <AiOutlineEye data-testid='show' />
                        }
                    </button>
                }
            </div>
            {inputError ? <p className="font-dmsans_r text-red text-12 leading-[16px]">{error}</p> : ''}
        </>
    )
}

Input.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    styles: PropTypes.string,
    style: PropTypes.string,
    maxLength: PropTypes.number,
    max: PropTypes.string,
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
    showError: PropTypes.bool,
    minLength: PropTypes.number
};