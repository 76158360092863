import { ReactComponent as Airtime } from "assets/svg/airtime.svg"
import { ReactComponent as Data } from "assets/svg/data.svg"
import { ReactComponent as Cable } from "assets/svg/cable.svg"
import { ReactComponent as Electricity } from "assets/svg/electricity.svg"
import { ReactComponent as WaterBill } from "assets/svg/waterbill.svg"
import { ReactComponent as Internet } from "assets/svg/internet.svg"
import { ReactComponent as Betting } from "assets/svg/betting.svg"
import { ReactComponent as Vouchers } from "assets/svg/vouchers.svg"
import { ReactComponent as VirtualCards } from "assets/svg/virtualcards.svg"
import { ReactComponent as Education } from "assets/svg/education.svg"

export const products = [
    {
        id: 1,
        icon: <Airtime/>,
        title: 'Airtime',
        url: '/products/airtime'
    },
    {
        id: 2,
        icon: <Data/>,
        title: 'Data',
        url: '/products/data'
    },
    {
        id: 3,
        icon: <Cable/>,
        title: 'Cable TV',
        url: '/products/cable'
    },
    {
        id: 4,
        icon: <Electricity/>,
        title: 'Electricity',
        url: '/products/electricity'
    },
    {
        id: 5,
        icon: <WaterBill/>,
        title: 'Water Bill',
        url: '/products/water'
    },
    {
        id: 6,
        icon: <Internet/>,
        title: 'Internet',
        url: '/products/internet'
    },
    {
        id: 7,
        icon: <Betting/>,
        title: 'Betting',
        url: '/products/betting'
    },
    {
        id: 8,
        icon: <Vouchers/>,
        title: 'Vouchers',
        url: '/products/vouchers'
    },
    {
        id: 9,
        icon: <VirtualCards/>,
        title: 'Virtual Cards',
        url: '/products/dollar-card'
    },
    {
        id: 10,
        icon: <Education/>,
        title: 'Education'
    },
]