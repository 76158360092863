import React, { useEffect, useState } from 'react';
import { ReactComponent as Schedule } from 'assets/svg/schedule.svg';
import { ReactComponent as Analytics } from 'assets/svg/analytics.svg';
import WalletFinesse from 'assets/images/wallet-finesse.png';
import Wallet600 from 'assets/images/wallet600.webp';
import SendMoney from 'assets/images/send-money.png';
import SendMoney600 from 'assets/images/send-money600.webp';
import Referral from 'assets/images/referral.png';
import Referral600 from 'assets/images/referral600.webp';
import { products } from 'constants/home';
import { VirtualCard, GetPouchersApp, Hero, Service } from 'containers/Home';
import CallToAction from 'components/CallToAction';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { POUCHERS_WEBSITE_URL, POUCHERS_LOGO_URL } from 'constants';

const description =
  'Pouchers is the best bill payment platform to pay bills like utilities, buy airtime, send money, schedule bills and shop around the world with virtual dollar cards all in one place.';
const title = 'Pouchers: Pay Bills, Buy Airtime, Shop with Virtual Cards';
const image = POUCHERS_LOGO_URL;
const url = `${POUCHERS_WEBSITE_URL}`;

export default function Home() {
  const navigate = useNavigate();

  const [dynamicDescription, setDynamicDescription] = useState(description);
  const [dynamicTitle, setDynamicTitle] = useState(title);

  useEffect(() => {
    // Fetch dynamic data or update dynamicDescription based on user interactions

    // Example:
    //   const newData = 'Dynamic description based on data or user interactions';
    setDynamicDescription(description);
    setDynamicTitle(title);
  }, []);

  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name="description" content={dynamicDescription} />

        <meta itemProp="name" content={dynamicTitle} />
        <meta itemProp="description" content={dynamicDescription ?? 'describe-description'} />
        <meta itemProp="image" content="" />

        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={dynamicTitle ?? 'hey-social'} />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={dynamicTitle ?? 'hey-social'} />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta name="title" content={dynamicTitle} />
        <meta name="description" content={dynamicDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={dynamicTitle} />
        <meta property="og:description" content={dynamicDescription} />
        <meta property="og:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={url} />
        <meta name="twitter:title" content={dynamicTitle} />
        <meta name="twitter:description" content={dynamicDescription} />
        <meta name="twitter:image" content={image} />
        {/* <title>Home - POUCHERS</title> */}
      </Helmet>
      <div className="bg-purple">
        <Hero />
      </div>
      <div className="py-[64px] bg-purple_transparent" id="products">
        <div className="container">
          <p className="text-purple text-16 font-inter text-center mb-[12px] font-semibold">
            Products/Services
          </p>
          <h1 className="text-36 font-altone_b text-dark text-center leading-[44px] mb-[64px]">
            Your one stop for all bills payment.
          </h1>
          <div className="bg-white rounded-[10px] px-[16px] lg:px-[40px] py-[16px]">
            <div className="grid grid-cols-3 md:grid-cols-5">
              {products.map((product) => (
                <Link key={product.id} to={product.url}>
                  <div className="flex flex-col justify-center py-[20px]">
                    <div className="flex justify-center">{product.icon}</div>
                    <p className="text-center text-16 font-dmsans_m">{product.title}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white overflow-hidden">
        <GetPouchersApp />
        <VirtualCard />
        <Service
          title="Schedule & Automate Bills "
          description={`Skip the annoying manual bill payment and let Pouchers automate your payments. With this feature, you can schedule your recurring bills to be paid daily, weekly, monthly or quarterly. `}
          image={<Schedule />}
          onClick={() => navigate('bill-scheduling-and-automation')}
          textLeft={false}
          showBtn={false}
        />
        <Service
          title="Spending Analytics"
          description={`Want to know where your money is going? Pouchers Spending Analytics helps you review your income and expenses over a specific period. With this info, you can make better financial decisions and plans.`}
          image={<Analytics />}
          onClick={() => navigate('spending-analytics')}
          textLeft={true}
          showBtn={false}
        />
        <Service
          title="Digital Wallet With Finesse"
          description={`No cash? No problem! Keep funds securely in your Pouchers e-wallet, fund it with vouchers or directly from your bank or cards, and manage your money on-the-go. Life doesn’t get easier than this.`}
          src={WalletFinesse}
          srcSet={`${Wallet600} 600w, ${WalletFinesse} 1600w`}
          onClick={() => navigate('digital-wallet')}
          textLeft={false}
          showBtn={false}
        />
        <Service
          title="Send Money Instantly Using User Tags"
          description={`Send and receive money with ease using Pouchers User Tags. No more long account details—just simple, free transfers to friends and family!`}
          src={SendMoney}
          srcSet={`${SendMoney600} 600w, ${SendMoney} 1600w`}
          onClick={() => navigate('money-transfer')}
          textLeft={true}
          showBtn={false}
        />
        <Service
          title="Referral Program You Can Earn From"
          description={`Earn some extra cash via the Pouchers referral program when you invite your friends to download the app and sign up. You get a bonus and in turn, they enjoy the perks of Pouchers. Everyone wins!`}
          src={Referral}
          srcSet={`${Referral600} 600w, ${Referral} 1600w`}
          onClick={() => navigate('referrals')}
          textLeft={false}
          showBtn={false}
        />
      </div>
      <CallToAction
        title="Ready to say bye to bills & hi to freedom?"
        description="Take charge with Pouchers—automate bills, track spending, and get virtual cards. It’s your all-in-one financial sidekick!"
      />
    </>
  );
}
